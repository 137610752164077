@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400&family=Roboto:wght@100&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
input {
  font-family: "Kanit", sans-serif !important;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ant-picker-ranges .ant-picker-ok .ant-btn {
  background: #1677ff;
  color: white;
}

.highcharts-container {
  display: flex;
  justify-content: center;
  width: auto !important;
  height: auto !important;
}

.highcharts-root {
  width: 90%;
  height: 80%;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
